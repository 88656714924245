import React from "react";
import { useDispatch } from "react-redux";
import { Logout } from "../../../store/actions/AuthActions";
//import { LOGOUT_ACTION } from "../../../store/actions/AuthActions"
export const MenuList = [
  //Dashboard
  {
    title: 'Dashboard',
    classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">grid_view</i>,
    to: '/app/dashboard',

  },


  {
    title: "All Users List",
    //classsChange: 'mm-collapse',
    // iconStyle: Widget,
    iconStyle: <i className="material-icons text-white">account_circle</i>,
    to: "/app/staking-user",
  },

  // {

  {
    title: "Reward Confirm ",
    //classsChange: 'mm-collapse',
    //iconStyle: Widget,
    iconStyle: <i className="material-icons">account_circle</i>,
    to: "/app/withdRawConfirm",
  },

  {
    title: 'Reward Approved ',
    //classsChange: 'mm-collapse',
    //iconStyle: Widget,
    iconStyle: <i className="material-icons">account_circle</i>,
    to: '/app/withdraw-approve',   //roi-approve
  },
  {
    title: "Reward Reject",
    //classsChange: 'mm-collapse',
    // iconStyle: Widget,
    iconStyle: <i className="material-icons text-white">account_circle</i>,
    to: "/app/reject-withdraw",
  },
  {
    title: "Wallet Request",
    //classsChange: 'mm-collapse',
    // iconStyle: Widget,
    iconStyle: <i className="material-icons text-white">account_circle</i>,
    to: "/app/all-wallet",
  },

  {
    title: "Logout",
    // classsChange: 'mm-collapse',
    iconStyle: <i class="material-icons"> logout </i>,
    onClick: Logout,
  },
];
