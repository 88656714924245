import React, { Fragment, useEffect, useState, useMemo } from "react";

import Web3 from "web3";
import { Row, Col, Card, Table } from "react-bootstrap";
import { changeWalletRequest, url, withdrawRoi } from "../../../services/api_function";

import { NotificationManager } from "react-notifications";
import moment from "moment";

export const ChangeWallet = () => {
    const [loading, setLoading] = useState(false);

    const [activeAmount, setActiveAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [approveLoading, setapproveLoading] = useState({});
    const [rejectLoading, setrejectLoading] = useState({});
    const [paymentMethod, setPaymentMethod] = useState("");
    const pageSize = 50;
    useEffect(() => {
        fetchData();
    }, [currentPage, search, paymentMethod]);

    const fetchData = async () => {
        try {
            const userDetails = localStorage.getItem("userDetails");
            const parsedDetails = JSON.parse(userDetails);
            const token = parsedDetails.token;

            const response = await changeWalletRequest(
                currentPage,
                { searchQuery: search, paymentMethod: paymentMethod },
                pageSize,
                token
            );
            setFilteredData(response?.data);
            const pages = Math.ceil(response?.totalCount / pageSize);
            setTotalPages(pages > 0 ? pages : 1);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage < totalPages ? prevPage + 1 : prevPage
        );
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };
    const handleSearch = async (e) => {
        const query = e.target.value.trim().toLowerCase();
        const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
        setSearch(sanitizedQuery);
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
    };

    const handleApprove = async (id) => {
        try {
            setapproveLoading((prev) => ({ ...prev, [id]: true }));
            const response = await fetch(`${url}/approve/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json();
            if (response.ok) {
                NotificationManager.success("Wallet request approved successfully");
                fetchData(); // Refresh the table after approval
            } else {
                NotificationManager.error(data.message || "Approval failed");
            }
        } catch (error) {
            console.error("Error approving request:", error);
            NotificationManager.error("An error occurred");
        } finally {
            setapproveLoading((prev) => ({ ...prev, [id]: false }));
        }
    };

    const handleReject = async (id) => {
        try {
            setrejectLoading((prev) => ({ ...prev, [id]: true }));
            const response = await fetch(`${url}/reject/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json();
            if (response.ok) {
                NotificationManager.success("Wallet request rejected successfully");
                fetchData(); // Refresh the table after rejection
            } else {
                NotificationManager.error(data.message || "Rejection failed");
            }
        } catch (error) {
            console.error("Error rejecting request:", error);
            NotificationManager.error("An error occurred");
        } finally {
            setrejectLoading((prev) => ({ ...prev, [id]: false }));
        }
    };



    return (
        <Fragment>
            <Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        marginBottom: "20px",
                    }}
                >
                    <div className="input-group" style={{ maxWidth: "300px" }}>
                        <input
                            type="search"
                            id="form1"
                            className="form-control"
                            placeholder="Search here..."
                            onChange={handleSearch}
                        />
                    </div>
                    <label class="form-label" for="form1"></label>
                </div>

                <Col lg={12}>
                    <Card>
                        <Card.Header
                            style={{ background: "black", border: "1px solid white" }}
                        >
                            <Card.Title style={{ color: "white", margin: "auto" }}>
                                Change Wallet Request
                            </Card.Title>
                           

                        </Card.Header>

                        <Card.Body
                            style={{ background: "black", border: "1px solid white" }}
                        >
                            <Table
                                responsive
                                style={{
                                    background: "black",
                                    color: "white",
                                    borderBottom: "1px solid white",
                                }}
                            >
                                <thead>
                                    <tr>

                                        <th>
                                            <strong>No</strong>
                                        </th>

                                        <th>
                                            <strong>email</strong>
                                        </th>

                                        <th>
                                            <strong>Old Wallet</strong>
                                        </th>


                                        <th>
                                            <strong>New Wallet </strong>
                                        </th>

                                        <th>
                                            <strong>TxnHash  </strong>
                                        </th>
                                        <th>
                                            <strong>Action</strong>
                                        </th>
                                        <th>
                                            <strong>Date&Time</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {filteredData && filteredData?.length > 0 ? (
                                        filteredData?.map((Data, index) => (
                                            <>

                                                <tr key={index}>

                                                    <td>{(currentPage - 1) * pageSize + index + 1}</td>

                                                    <td>{Data?.email}</td>

                                                    <td>{Data?.oldWallet}</td>
                                                    <td>{Data?.newWallet}</td>
                                                    <td>
                                                        {(Data?.trxnHash)}
                                                    </td>




                                                    <td>
                                                        {" "}
                                                        <td>

                                                            {Data?.status ?  
                                                            <span   style={{
                                                              
                                                                padding: "3px 6px",
                                                                color: "white",
                                                                backgroundColor: "green",
                                                                fontWeight: "bold",
                                                                border: "none",
                                                                borderRadius: "10px",
                                                                outline: "none",
                                                            }}>Approved</span>
                                                        :
                                                        <>
                                                        
                                                        <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    padding: "5px 10px",
                                                                    color: "white",
                                                                    backgroundColor: "red",
                                                                    fontWeight: "bold",
                                                                    border: "none",
                                                                    borderRadius: "4px",
                                                                    outline: "none",
                                                                }}
                                                                onClick={() => handleReject(Data?._id)}
                                                                disabled={rejectLoading[Data?._id]}
                                                            >
                                                                {rejectLoading[Data?._id] ? "Rejecting..." : "Reject"}
                                                            </button>

                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    padding: "5px 10px",
                                                                    color: "white",
                                                                    backgroundColor: "green",
                                                                    fontWeight: "bold",
                                                                    border: "none",
                                                                    borderRadius: "4px",
                                                                    outline: "none",
                                                                    marginLeft: "5px",
                                                                }}
                                                                onClick={() => handleApprove(Data?._id)}
                                                                disabled={approveLoading[Data?._id]}
                                                            >
                                                                {approveLoading[Data?._id] ? "Approving..." : "Approve"}
                                                            </button>
                                                        </>
                                                        }
                                                          
                                                        </td>

                                                    </td>
                                                    <td>{moment((Data?.createdAt)).fromNow()}</td>

                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-between">
                                <span>
                                    <strong>{/* {currentPage} of {totalPages} */}</strong>
                                </span>
                            </div>
                            <div
                                className="text-center mb-3 col-lg-6"
                                style={{ margin: "auto" }}
                            >
                                <div className="filter-pagination  mt-3 bg-black">
                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        style={{
                                            background:
                                                "linear-gradient(90deg, rgb(0 255 213) 15.9%, rgb(0 227 255) 98.32%)",
                                            color: "#000",
                                        }}
                                    >
                                        {"<<"}
                                    </button>

                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        style={{
                                            background:
                                                "linear-gradient(90deg, rgb(0 255 213) 15.9%, rgb(0 227 255) 98.32%)",
                                            color: "#000",
                                        }}
                                    >
                                        Previous
                                    </button>

                                    <button
                                        className="next-button"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        style={{
                                            background:
                                                "linear-gradient(90deg, rgb(0 255 213) 15.9%, rgb(0 227 255) 98.32%)",
                                            color: "#000",
                                        }}
                                    >
                                        Next
                                    </button>

                                    <button
                                        className="next-button"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        style={{
                                            background:
                                                "linear-gradient(90deg, rgb(0 255 213) 15.9%, rgb(0 227 255) 98.32%)",
                                            color: "#000",
                                        }}
                                    >
                                        {">>"}
                                    </button>

                                    <span className="text-white">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};


